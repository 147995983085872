var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div'),_c('div',[_c('v-data-table',{staticClass:"flex d-block mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"single-expand":true,"expand-icon":"fas fa-chevron-down","item-class":function (item){return item.tipo==60?'row-style-nc':''},"item-key":"id","items-per-page":10,"sort-by":"date_emited","show-expand":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-4",attrs:{"value":true,"icon":"fas fa-info-circle"}},[_vm._v(" No existen facturas registradas ")])]},proxy:true},{key:"item.document_id",fn:function(ref){
var item = ref.item;
return [_c('small',{class:((_vm.estatuses.find(function (e){ return e.value==item.estatus; }).color) + "--text")},[_vm._v(_vm._s(_vm.estatuses.find(function (e){ return e.value==item.estatus; }).text))]),(item.tipo==60)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":"error"}},[_vm._v(" NC ")]):_vm._e(),_c('div',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.document_id)+" ")])]}},{key:"item.date_emited",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date_emited,'DD/MM/YYYY'))+" ")])]}},{key:"item.rznSocRecep",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.rutRecep))]),_c('div',{staticClass:"d-flex d-block"},[_vm._v(" "+_vm._s(item.rznSocRecep)+" ")])]}},{key:"item.get_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.get_total,'$', 0))+" ")]}},{key:"item.payed",fn:function(ref){
var item = ref.item;
return [_c('div',{class:(item.get_total - item.get_payed)>0?'red--text':''},[_vm._v(_vm._s(_vm._f("currency")(item.get_payed,'$', 0)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4 px-10",attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',[_c('invoice-detail',{attrs:{"invoice":item,"inSet":true}})],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }