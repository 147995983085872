<template>
<div>
    <div v-if="loading" class="text-center">
        <v-progress-circular
        color="primary"
        indeterminate
        rounded
        height="6"
        v-show="loading || saving"
    ></v-progress-circular>
    </div>
    <div class="d-flex" v-if="items.length>0">
        <div class="mr-2">
            Kame
        </div>
        <v-chip small outlined class="mr-1 orange" v-for="item in items" :key="item.id">
            {{item.document}}
        </v-chip>
    </div>
</div>
</template>
<script>

import {fetchDocuments} from '@/api/kame'
export default {
    name: 'SoftnetDocumentsChips',
    props:['work_order_id'],
    components: {
        
    },
    data() {
        return {
            loading: false,
            items:[],
            enabled:this.$store.getters.getCompany.kame_integration_enabled,
        }
    },
    methods: {
        async fetch(){
            this.items=[]
            if(this.work_order_id&&this.enabled){
                this.loading = true
                const query=`page=1&ordering=created_at&workorder=${this.work_order_id}`
                await fetchDocuments(query)
                .then(res=>{
                    console.log(res)
                    this.items=res.results
                    this.loading = false
                })
                .catch((err)=>{
                    notifications.showError('Error cargando documentos '+err)
                    this.loading = false
                })
            }
        }
    },
    mounted() {
        if(this.work_order_id){
            this.fetch()
        }
    },
    watch:{
        work_order_id:{
            handler:'fetch',
        }
    }
}
</script>
