<template>
<div>
  <div>
    <!-- LOADER -->
  </div>
  <div>
    <!-- MAIN DATA -->
    <v-data-table
      :headers="headers"
      :items="items"
      :single-expand="true"
      expand-icon="fas fa-chevron-down"
      item-key="id"
			:loading="loading"
      show-expand
      class="flex d-block mt-4"
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-alert :value="true" icon="fas fa-info-circle" class="ma-4">
          No existen ordenes vinculadas
        </v-alert>
      </template>
      <template v-slot:top>
        <!-- <div class="text text-h6 pa-4">Facturas Registradas</div> -->
      </template>
      <template v-slot:item.date="{ item }">
        <div class="text">{{ item.created_at | moment("DD/MM/YYYY") }}</div>
      </template>
      <template v-slot:item.total="{ item }">
        <div class="text text-end">
          {{ totalOrder(item) | currency("$", 0) }}
        </div>
      </template>
      <template v-slot:item.estatus="{ item }">
        <v-chip small label outlined :color="getStatusColor(item)" style="">{{
          getStatusName(item)
        }}</v-chip>
      </template>
      <template v-slot:item.delete="{ item }">
        <div class="text text-center">
          <v-icon class="error--text" x-small @click="removeInvoice(item)"
            >fas fa-trash</v-icon
          >
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="py-4 px-10" :colspan="headers.length">
          <detailed-purchase-order :purchaseOrder="item" :inSet="true" />
        </td>
      </template>
    </v-data-table>
  </div>
</div>
</template>
<script>
import { fetchPurchase } from "@/api/stocks";
import DetailedPurchaseOrder from "@/components/stocks/DetailedPurchaseOrder";

export default {
  name: "PurchaseOrders",
  components: {
    DetailedPurchaseOrder,
  },
  props: ["workorder"],
  data() {
    return {
      headers: [
        { text: "Numero", value: "id", class: "table-header2" },
        { text: "Creada", value: "date", class: "table-header2" },
        {
          text: "Proveedor",
          value: "provider_name",
          class: "table-header2",
          align: "start",
        },
        {
          text: "Referencia",
          value: "title",
          class: "table-header2",
          align: "end",
        },
        { text: "Neto", value: "total", class: "table-header2", align: "end" },
        {
          text: "Estatus",
          value: "estatus",
          class: "table-header2",
          align: "end",
        },
        {
          text: "",
          value: "data-table-expand",
          class: "table-header2",
          aligment: "end",
        },
      ],
      estatuses: [
        {
          value: 1,
          text: "Ingresada",
          color: "info",
        },
        {
          value: 2,
          text: "Aprobada",
          color: "success",
        },
        {
          value: 4,
          text: "Recepcionada",
          color: "",
        },
        {
          value: 3,
          text: "Anulada",
          color: "error",
        },
      ],
      loading: false,
			items: [],
    };
  },
  methods: {
    getPurchaseOrders() {
      this.loading = true;
      const query = `work_order=${this.workorder.id}`;
      fetchPurchase(query)
        .then((response) => {
          this.items = response.results;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
		totalOrder(item) {
			const total = item.details.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
      item.neto=total
      return total-total*parseFloat(item.discount)/100
		},
		getStatusName(item){
      return this.estatuses.find(i=>i.value==item.estatus).text
    },
    getStatusColor(item){
      return this.estatuses.find(i=>i.value==item.estatus).color
    },
  },
	created(){
		this.getPurchaseOrders();
	},
};
</script>
