<template>
    <div v-if="loading" class="text-center">
        <v-progress-circular
        color="primary"
        indeterminate
        rounded
        height="6"
        v-show="loading || saving"
    ></v-progress-circular>
    </div>
    <div v-else @click.stop="show" class="pointer">
        <slot ></slot>
        <v-dialog  v-model='display' max-width="1200">
            <v-card class="" >
            <detail :loading="loading" :work_order='workOrderOnDisplay' @close="display=false"/> 
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { getWorkOrder } from "@/api/production";
import WorkOrderCard from '@/components/production/WorkOrderCard'
import Detail from './workorder/Detail'
import notifications from "@/utils/notifications";
export default {
    name:'ShowWorkOrder',
    components:{Detail},
    props:['work_order_id'],
    data() {
        return {
            loading:false,
            workOrderOnDisplay:null,
            display:false,
        }
    },
    methods:{
        show(){
            this.loading=true
            getWorkOrder(this.work_order_id).then(response=>{
                this.workOrderOnDisplay=response
                //mostrar los productos ordenados por sales_note_key
                this.workOrderOnDisplay.products.sort((a,b)=>a.sales_note_key-b.sales_note_key)
                this.loading=false
                this.display=true
            }).catch(error=>{
                this.loading=false
                notifications.showError(error)
            })
        },
    }
}

</script>

<style>
  .pointer{
    cursor: pointer;
  }

</style>
