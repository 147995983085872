<template>
<div>
<div>
    <!-- LOADER -->
</div>
    <div>
        <!-- MAIN DATA -->
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :single-expand="true"
            expand-icon="fas fa-chevron-down"
            :item-class="(item)=>{return item.tipo==60?'row-style-nc':''}"
            item-key="id"
            :items-per-page="10"
            sort-by="date_emited"
            show-expand
            class="flex d-block mt-4"
            hide-default-footer
            >
            <template v-slot:no-data>
                <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                No existen facturas registradas
                </v-alert>
            </template>
            <template v-slot:item.document_id="{ item }">
                <small :class="`${estatuses.find(e=>e.value==item.estatus).color}--text`">{{estatuses.find(e=>e.value==item.estatus).text}}</small>
                <v-chip
                v-if="item.tipo==60" 
                x-small
                color="error"
                class="ml-2"
                >
                NC
                </v-chip>
                <div class="d-block">
                {{item.document_id}}
                </div>
            </template>
            <template v-slot:item.date_emited="{ item }">
                <div class="d-block">
                {{item.date_emited| moment('DD/MM/YYYY')}}
                </div>
            </template>
            <template v-slot:item.rznSocRecep="{ item }">
                <small>{{item.rutRecep}}</small>
                <div class="d-flex d-block">
                {{item.rznSocRecep}}
                </div>
            </template>
            <template v-slot:item.get_total="{ item }">
                {{item.get_total| currency('$', 0)}}
            </template>
            <template v-slot:item.payed="{ item }">
                <div :class="(item.get_total - item.get_payed)>0?'red--text':''">{{item.get_payed| currency('$', 0)}}</div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td class="py-4 px-10" :colspan="headers.length">
                <v-row>
                    <v-col>
                    <invoice-detail :invoice="item" :inSet="true"/>
                    </v-col>
                </v-row>
                </td>
            </template> 
        </v-data-table>
    </div>
</div>
</template>
<script>
import { fetchDTE } from '@/api/salesNotes'
import InvoiceDetail from '@/components/sales/InvoiceDetail'
import notifications from '@/utils/notifications'
export default{
    name:'Invoices',
    components:{InvoiceDetail},
    props:['workorder'],
    data(){
        return{
            loading:false,
            items:[],
            headers:[
            {
                text: 'Número',
                align: 'start',
                value: 'document_id',
                class: 'table-header2',
                show:true,
                align:'start'
            },
            {
                text: 'Fecha',
                align: 'start',
                value: 'date_emited',
                class: 'table-header2',
                show:true
            },
            {
                text: 'Razón Social',
                align: 'start',
                value: 'rznSocRecep',
                class: 'table-header2',
                show:true
            },
            {
                text: 'Total',
                value: 'get_total',
                class: 'table-header2',
                show:true,
                sortable:false,
                align:'end'
            },
            {
                text: 'Pagado',
                value: 'payed',
                class: 'table-header2',
                show:true,
                sortable:false,
                align:'end'
            },
            {
                text: '',
                align: 'start',
                value: 'actions',
                class: 'table-header2',
                sortable:false,
                show:true,
                align:'end'
            },
            ],
            estatuses:[
                {
                value:1,
                text:'Ingresada',
                color:'info'
                },
                {
                value:2,
                text:'Aprobada',
                color:'success'
                },
                {
                value:4,
                text:'Recepcionada',
                color:''
                },
                {
                value:3,
                text:'Anulada',
                color:'error'
                },
            ],
            
        }
    },
    methods:{
        getInvoices(){
            this.loading = true
            const query=`work_order=${this.workorder.id}`
            fetchDTE(query).then(data=>{
                this.items = data.results.filter(i=>i.estatus<3)
            })
            .catch(err=>{
                notifications.showError(err)
            })
            .finally(()=>{
                this.loading = false
            })
        },
    },
    async created(){
        this.getInvoices()
    }
}

</script>